<template>
  <CCardBody>
    <CRow>
      <CCol col="3" class="offset-9">
        <CButton
          @click="$emit('click:next')"
          style="width:100%"
          color="info">次へ</CButton>
      </CCol>
    </CRow>
  </CCardBody>
</template>

<script>
export default {
  name: "NextButton"
}
</script>

<style scoped>

</style>
