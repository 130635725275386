<template>
  <CCardHeader style="padding:3px 20px 3px 20px">
    <CRow>
      <CCol col="1" style="padding:0 5px 0 5px">
        <CButton
          style="padding:6px 5px 6px 5px"
          @click="$emit('click:back')"
          v-c-tooltip="{content: '一つ戻る', active:false }"
          variant="ghost">
          <CIcon name="cil-chevron-left"/>
        </CButton>
      </CCol>
      <CCol col="10" style="padding:12px 0 6px 0">
        <CProgress :value="value" :max="max" show-percentage animated></CProgress>
      </CCol>
      <CCol col="1" style="padding:0 5px 0 5px">
        <CButton
          @click="$emit('click:init')"
          style="padding:6px 5px 6px 5px"
          v-c-tooltip.mouseenter="{
            content: '最初に戻る'
          }"
          variant="ghost"
        >
          <CIcon name="cil-list-high-priority"/>
        </CButton>
      </CCol>
    </CRow>
  </CCardHeader>

</template>

<script>
export default {
  name: "CreateByStepCardHeader",
  props: {
    value: '',
    max: ''
  },
  data(){
    return {
    }
  }
}
</script>

<style scoped>

</style>
