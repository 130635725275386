<template>
  <CRow style="width:100%">
    <CCardBody v-if="isUploaded">
      <div class="sk-grid">
        <div class="sk-grid-cube"></div>
        <div class="sk-grid-cube"></div>
        <div class="sk-grid-cube"></div>
        <div class="sk-grid-cube"></div>
        <div class="sk-grid-cube"></div>
        <div class="sk-grid-cube"></div>
        <div class="sk-grid-cube"></div>
        <div class="sk-grid-cube"></div>
        <div class="sk-grid-cube"></div>
      </div>
      <div style="width:100%;text-align: center; margin-top: 10px">アップロード中....</div>
    </CCardBody>
    <CCol v-if="!isUploaded">
      <!-- アップロードする画像をループして表示するエレメント -->
      <CRow v-if="images.length > 0" style="margin-bottom: 20px">
        <CCol :col="new_img_size" v-for="(image, index) in images" style="margin-bottom: 10px" @click="setImage(index)">
          <CImg
            width="100%"
            height="auto"
            shape="rounded"
            :src="image.base64"
          />
          <CRow>
            <CCol class="col-12 text-center" style="padding:6px 15px 6px 15px">
              <CIcon v-if="chkFileSize(image.size)" size="lg" name="cil-check-circle" style="color:darkgreen;"></CIcon>
              <CIcon v-if="!chkFileSize(image.size)" size="lg" name="cil-x" style="color:red;"></CIcon>
              サイズ: {{ fileSize(image.size) }}
            </CCol>
          </CRow>
        </CCol>
      </CRow>
      <!-- Croppie -->
      <!-- ファイルを選択するボタン -->
      <CRow>
        <CCol v-if="images.length === 0" lg="10" col="10" class="offset-1 offset-lg-1" style="height:200px">
          <label class="my-label">アップロードする写真を選択する
            <input
              type="file"
              multiple
              accept="image/jpeg"
              style="display: none"
              v-on:change="loadFiles"
            />
          </label>
        </CCol>
        <CCol v-if="images.length > 0" lg="4" col="12" class="offset-lg-8">
          <CButton
            style="width:100%"
            @click="upload"
            size="sm"
            color="primary"
          >アップロード
          </CButton>
        </CCol>
      </CRow>
    </CCol>
  </CRow>
</template>

<script>
import Comm from '@/views/v1/lib/Comm.js'
import imageCompression from "browser-image-compression"

export default {
  name: "UploadPicture",
  mixins: [Comm],/* ここでミックスインを追加 */
  comments: {
  },
  props: {
    job_id: {
      required: true
    },
    is_close: {
      type: Boolean,
      required: true
    }
  },
  watch: {
    is_close(value){
      this.images = []
    }
  },
  data() {
    return {
      isUploaded: false,
      images: [],
      // compressed_images: [],
      // files: [],
      // cropped: null,
    }
  },
  mounted() {
  },
  computed: {
    new_img_size: function () {
      return '3'
    },
    chkFileSize: function () {
      return function (value) {
        if (value > 3 * 1024 * 1024) {
          return false
        }
        return true
      }
    },
    fileSize: function () {
      return function (value) {
        if (value === 0 || value === '') {
          return "0バイト"
        }
        if (value < 1024) {
          return String(value) + 'Byte'
        }
        if (value < 1024 * 1024) {
          return String((Number(value) / 1024).toFixed(2)) + 'KB'
        }
        return String((Number(value) / 1024 / 1024).toFixed(2)) + 'MB'
      }
    },
  },
  methods: {
    //圧縮された画像を生成する
    //https://qiita.com/nobu17/items/64f51d43827424db4b6a
    async getCompressImageFileAsync(file) {
      const options = {
        maxSizeMB: 1, // 最大ファイルサイズ
        maxWidthOrHeight: 600 // 最大画像幅もしくは高さ
      };
      try {
        // 圧縮画像の生成
        return await imageCompression(file, options);
      } catch (error) {
        console.error("getCompressImageFileAsync is error", error);
        throw error;
      }
    },
    async upload() {
      this.isUploaded = true
      const item = {
        family_id: this.$store.state.family_id,
        user_id: this.$store.state.user_id,
        job_id: this.job_id,//job_id=0は自分のアイコン画像
        images: this.images
      }
      const response = await this._imageUpload(item)
      if(response === true){
        window.alert("アップロードしました！")
      }else{
        window.alert("アップロード失敗しました。。")
      }
      this.images = []
      this.isUploaded = false
      this.$emit("update:show", false)
    },
    loadFiles(event) {
      const e = event.target.files
      Object.keys(e).map(async key => {
        if (e[key].type === 'image/jpeg' || e[key].type === 'image/png') {
          const comp_b64_img = await this.getBase64(await this.getCompressImageFileAsync(e[key]))
          //https://chusotsu-program.com/js-base64-encode/
          this.images.push({
            name: e[key].name,
            size: e[key].size,
            type: e[key].type,
            // base64: base64_img,
            base64: comp_b64_img,
          })
        }
      }, this)
    },
    async getBase64(file) {
      /* Promiseにアロー関数を渡して非同期処理を書く。*/
      return new Promise((resolve, reject) => {

        /* 組み込みオブジェクトを使い、Base64エンコードされた画像データを取得  */
        const reader = new FileReader()
        reader.readAsDataURL(file)

        /* 成功時と失敗時の処理。resolveの引数に結果を渡して、のちの.then(result => ...)で受け取る  */
        reader.onload = () => resolve(reader.result)
      })
    },
  }
}
</script>
<style src="spinkit/spinkit.min.css"></style>

<style scoped>
.card-body div{
  margin: auto;
}
label.my-label {
  display: block;
  /*width: 160px;*/
  padding: 0.8em;
  text-align: center;
  text-decoration: none;
  color: #4c4f54;
  border: 2px dotted #4c4f54;
  border-radius: 3px;
  transition: .4s;
  width:100%;
  height:100%
}

label.my-label:hover {
  background: #4c4f54;
  color: #fff;
}
</style>
