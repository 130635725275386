<template>
  <div>
    <CButton
      v-if="false"
    >SHOW
    </CButton>
    <!-- (0)履歴 or 新規のカード -->
    <CCard v-if="showCards[0]" class="anim-box zoomin anim is-animated">
      <CCardBody>
        <CRow>
          <CCol col="6">
            <CButton
              style="width:100%; height:100px"
              variant="outline"
              @click="moveNextCard"
              color="info">履歴から作成する</CButton>
          </CCol>
          <CCol col="6">
            <CButton
              style="width:100%; height:100px"
              variant="outline"
              @click="moveNextNextCard"
              color="info">新しく作成する</CButton>
          </CCol>
        </CRow>
      </CCardBody>
    </CCard>
    <!-- (1)履歴のカード -->
    <CCard v-if="showCards[1]" class="anim-box zoomin anim is-animated">
      <card-header :value="progressValue()" :max="100" v-on:click:back="moveBackCard"  v-on:click:init="moveToZero"/>
      <next-button v-if="templates.length > 0" v-on:click:next="moveNextCard"/>
      <CCardBody style="margin-top:-30px; margin-bottom: -20px">
        <CListGroup>
          <CListGroupItem
            v-for="template in templates"
            :active="template['active']"
            :action="true"
            @click="clickTemplateItem(template)"
            color="dark"
            class="flex-column align-items-start">
            <!-- お仕事タイトル -->
            <div class="d-flex w-100 justify-content-between"><h5 class="mb-1">{{template["title"]}}</h5></div>
            <!-- お仕事詳細 -->
            <p class="mb-1">{{template['job_detail']}}</p>
            <!-- 作成日と作成者 -->
            <small>{{getShortDate(template["created"], 'JST', 9)}} by {{_get_nickname(template['client_id'])}}</small>
          </CListGroupItem>
        </CListGroup>
      </CCardBody>
      <next-button v-if="templates.length > 0" v-on:click:next="moveNextCard"/>
    </CCard>
    <!-- (2)タイトル入力のカード -->
    <CCard v-if="showCards[2]" class="anim-box zoomin anim is-animated">
      <card-header :value="progressValue()" :max="100" v-on:click:back="moveBackCard"  v-on:click:init="moveToZero"/>
      <CCardBody>
        <CRow>
          <CCol col="12">
            お仕事のタイトルを入力してください。
          </CCol>
          <CCol col="12">
            <CTextarea
              :row="1"
              :isValid="item_validation.title"
              invalidFeedback="タイトルの入力は必須です。"
              v-model="item.title"></CTextarea>
          </CCol>
        </CRow>
      </CCardBody>
      <next-button v-on:click:next="moveNextCard('title')"/>
    </CCard>
    <!-- (3)お仕事詳細入力のカード -->
    <CCard v-if="showCards[3]" class="anim-box zoomin anim is-animated">
      <card-header :value="progressValue()" :max="100" v-on:click:back="moveBackCard"  v-on:click:init="moveToZero"/>
      <CCardBody>
        <CRow>
          <CCol col="12">
            お仕事の説明をなるべく詳細に記載してください。
          </CCol>
          <CCol col="12">
            <CTextarea
              :isValid="item_validation.job_detail"
              invalidFeedback="詳細の入力は必須です。"
              v-model="item.job_detail"></CTextarea>
          </CCol>
        </CRow>
      </CCardBody>
      <next-button v-on:click:next="moveNextCard('job_detail')"/>
    </CCard>
    <!-- (4)リンクページ入力のカード -->
    <CCard v-if="showCards[4]" class="anim-box zoomin anim is-animated">
      <card-header :value="progressValue()" :max="100" v-on:click:back="moveBackCard"  v-on:click:init="moveToZero"/>
      <CCardBody>
        <CRow>
          <CCol col="12">
            <div>お仕事の参考になるページやリクエストのページがあったら入力してください。</div>
            <div>追加ボタンを押すことで複数のページが登録可能です。</div>
          </CCol>
          <CCol col="12">
            <CInput></CInput>
          </CCol>
        </CRow>
      </CCardBody>
      <next-button v-on:click:next="moveNextCard"/>
    </CCard>
    <!-- (5)募集開始日程入力のカード -->
    <CCard v-if="showCards[5]" class="anim-box zoomin anim is-animated">
      <card-header :value="progressValue()" :max="100" v-on:click:back="moveBackCard"  v-on:click:init="moveToZero"/>
      <CCardBody>
        <CRow style="margin-bottom: 10px">
          <CCol col="12">
            いつから募集を開始しますか？
          </CCol>
        </CRow>
        <CRow style="margin-bottom: 25px">
          <CCol col="12">
            <CListGroup horizontal flush>
              <CListGroupItem
                v-for="(option, index) in startDateOptions"
                :active="option['active']"
                :action="true"
                @click="clickDate(index, 'startDate')"
                color="light"
                class="flex-column align-items-start text-center">
                {{option['label']}}</CListGroupItem>
            </CListGroup>
          </CCol>
        </CRow>
        <CRow>
          <CCol col="12" lg="6" class="offset-lg-6">
            <CInput
              :isValid="item_validation.start_date"
              invalidFeedback="募集開始日の入力は必須です。"
              label="※日付の指定はこちらから"
              type="date"
              v-model="item.start_date"
            />
          </CCol>
        </CRow>
      </CCardBody>
      <next-button v-on:click:next="moveNextCard('start_date')"/>
    </CCard>
    <!-- (6)開始日程入力のカード -->
    <CCard v-if="showCards[6]" class="anim-box zoomin anim is-animated">
      <card-header :value="progressValue()" :max="100" v-on:click:back="moveBackCard"  v-on:click:init="moveToZero"/>
      <CCardBody>
        <CRow style="margin-bottom: 10px">
          <CCol col="12">
            お仕事を始めてもらうのはいつからですか？
          </CCol>
        </CRow>
        <CRow style="margin-bottom: 25px">
          <CCol col="12">
            <CListGroup horizontal flush>
              <CListGroupItem
                v-for="(option, index) in endDateOptions"
                :active="option['active']"
                :action="true"
                @click="clickDate(index, 'endDate')"
                color="light"
                class="flex-column align-items-start text-center">
                {{option['label']}}</CListGroupItem>
            </CListGroup>
          </CCol>
        </CRow>
        <CRow>
          <CCol col="12" lg="6" class="offset-lg-6">
            <CInput
              :isValid="item_validation.end_date"
              invalidFeedback="お仕事開始日の入力は必須です。"
              label="※日付の指定はこちらから"
              type="date"
              v-model="item.end_date"
            />
          </CCol>
        </CRow>
      </CCardBody>
      <next-button v-on:click:next="moveNextCard('end_date')"/>
    </CCard>
    <!-- (7)終了日程入力のカード -->
    <CCard v-if="showCards[7]" class="anim-box zoomin anim is-animated">
      <card-header :value="progressValue()" :max="100" v-on:click:back="moveBackCard"  v-on:click:init="moveToZero"/>
      <CCardBody>
        <CRow>
          <CCol col="12">
            お仕事はいつまでに終えてもらいますか？
          </CCol>
        </CRow>
        <CRow style="margin-bottom: 25px">
          <CCol col="12">
            <CListGroup horizontal flush>
              <CListGroupItem
                v-for="(option, index) in finishDateOptions"
                :active="option['active']"
                :action="true"
                @click="clickDate(index, 'finishDate')"
                color="light"
                class="flex-column align-items-start text-center">
                {{option['label']}}</CListGroupItem>
            </CListGroup>
          </CCol>
        </CRow>
        <CRow>
          <CCol col="12" lg="6" class="offset-lg-6">
            <CInput
              :isValid="item_validation.finish_date"
              invalidFeedback="お仕事終了日の入力は必須です。"
              label="※日付の指定はこちらから"
              type="date"
              v-model="item.finish_date"
            />
          </CCol>
        </CRow>      </CCardBody>
      <next-button v-on:click:next="moveNextCard('finish_date')"/>
    </CCard>
    <!-- (8)カテゴリ入力のカード -->
    <CCard v-if="showCards[8]" class="anim-box zoomin anim is-animated">
      <card-header :value="progressValue()" :max="100" v-on:click:back="moveBackCard"  v-on:click:init="moveToZero"/>
      <CCardBody>
        <CRow>
          <CCol col="12">
            お仕事のカテゴリとして一番近いものを選択してください。
          </CCol>
        </CRow>
        <CRow>
          <CCol col="6">
            <CSelect
              :options="$store.state.job_categories"
            />
          </CCol>
        </CRow>
      </CCardBody>
      <next-button v-on:click:next="moveNextCard"/>
    </CCard>
    <!-- (9)募集金額入力のカード -->
    <CCard v-if="showCards[9]" class="anim-box zoomin anim is-animated">
      <card-header :value="progressValue()" :max="100" v-on:click:back="moveBackCard"  v-on:click:init="moveToZero"/>
      <CCardBody>
        <CRow>
          <CCol col="12">
            <div>金額を入力してください。</div>
          </CCol>
          <CCol col="6">
            <CInput
              :isValid="item_validation.price"
              invalidFeedback="金額の入力は必須です。"
              prepend="¥"
              v-model="item.price"></CInput>
          </CCol>
        </CRow>
      </CCardBody>
      <next-button v-on:click:next="moveNextCard('price')"/>
    </CCard>
    <!-- (10)最後の確認のカード -->
    <CCard v-if="showCards[10]" class="anim-box zoomin anim is-animated">
      <card-header :value="progressValue()" :max="100" v-on:click:back="moveBackCard"  v-on:click:init="moveToZero"/>
      <CCardBody>
        <CRow style="margin-bottom: 10px">
          <CCol col="12">
            以下の内容で間違いなければ「作成する！」をクリックしてください。
          </CCol>
        </CRow>
        <CRow>
          <!--タイトル-->
          <CCol col="12">
            <CTextarea
              v-model="item.title"
              :disabled="item_disabled.title">
              <template v-slot:label>
                <CButton
                  @click="item_disabled.title = !item_disabled.title"
                  style="padding:0"
                  variant="ghost"
                  v-c-tooltip.mouseenter="{content: '編集'}"
                ><CIcon name="cil-pen"/>
                </CButton>
                <span style="margin-left:8px">お仕事のタイトル</span>
              </template>
            </CTextarea>
          </CCol>
          <!--お仕事詳細-->
          <CCol col="12">
            <CTextarea
              v-model="item.job_detail"
              :disabled="item_disabled.job_detail">
              <template v-slot:label>
                <CButton
                  @click="item_disabled.job_detail = !item_disabled.job_detail"
                  style="padding:0"
                  variant="ghost"
                  v-c-tooltip.mouseenter="{content: '編集'}"
                ><CIcon name="cil-pen"/>
                </CButton>
                <span style="margin-left:8px">お仕事の詳細</span>
              </template>
            </CTextarea>
          </CCol>
          <!--募集開始日-->
          <CCol col="12">
            <CInput
              type="date"
              v-model="item.start_date"
              :disabled="item_disabled.start_date">
              <template v-slot:label>
                <CButton
                  @click="item_disabled.start_date = !item_disabled.start_date"
                  style="padding:0"
                  variant="ghost"
                  v-c-tooltip.mouseenter="{content: '編集'}"
                ><CIcon name="cil-pen"/>
                </CButton>
                <span style="margin-left:8px">募集開始日</span>
              </template>
            </CInput>
          </CCol>
          <!--開始日-->
          <CCol col="12">
            <CInput
              type="date"
              v-model="item.end_date"
              :disabled="item_disabled.end_date">
              <template v-slot:label>
                <CButton
                  @click="item_disabled.end_date = !item_disabled.end_date"
                  style="padding:0"
                  variant="ghost"
                  v-c-tooltip.mouseenter="{content: '編集'}"
                ><CIcon name="cil-pen"/>
                </CButton>
                <span style="margin-left:8px">お仕事開始日</span>
              </template>
            </CInput>
          </CCol>
          <!--終了日-->
          <CCol col="12">
            <CInput
              type="date"
              v-model="item.finish_date"
              :disabled="item_disabled.finish_date">
              <template v-slot:label>
                <CButton
                  @click="item_disabled.finish_date = !item_disabled.finish_date"
                  style="padding:0"
                  variant="ghost"
                  v-c-tooltip.mouseenter="{content: '編集'}"
                ><CIcon name="cil-pen"/>
                </CButton>
                <span style="margin-left:8px">お仕事終了日</span>
              </template>
            </CInput>
          </CCol>
          <!-- カテゴリ -->
          <CCol col="12">
            <CInput
              label="お仕事カテゴリ"
              v-model="item.category_id"
              :disabled="item_disabled.category_id">
              <template v-slot:label>
                <CButton
                  @click="item_disabled.category_id = !item_disabled.category_id"
                  style="padding:0"
                  variant="ghost"
                  v-c-tooltip.mouseenter="{content: '編集'}"
                ><CIcon name="cil-pen"/>
                </CButton>
                <span style="margin-left:8px">お仕事カテゴリ</span>
              </template>
            </CInput>
          </CCol>
          <!-- 価格 -->
          <CCol col="12">
            <CInput
              v-model="item.price"
              :disabled="item_disabled.price">
              <template v-slot:label>
                <CButton
                  @click="item_disabled.price = !item_disabled.price"
                  style="padding:0"
                  variant="ghost"
                  v-c-tooltip.mouseenter="{content: '編集'}"
                ><CIcon name="cil-pen"/>
                </CButton>
                <span style="margin-left:8px">価格</span>
              </template>
            </CInput>
          </CCol>
        </CRow>
      </CCardBody>
      <CCardBody>
        <CRow>
          <CCol lg="3" col="5" class="offset-lg-9 offset-7">
            <CButton
              style="width:100%"
              :disabled="buttonCreateJob"
              @click="createJob"
              color="success">作成する</CButton>
          </CCol>
        </CRow>
      </CCardBody>

    </CCard>
  </div>
</template>

<script>
import Comm from '@/views/v1/lib/Comm.js'
import CreateByStepCardHeader from "@/views/v1/components/CreateJobByStep/CreateByStepCardHeader";
import NextButton from "@/views/v1/components/CreateJobByStep/NextButton";

export default {
  name: "CreateJobStepByStep",
  mixins: [Comm],/* ここでミックスインを追加 */
  props: {},
  mounted() {
    for(let i=0;i < this.numOfCards;i++){
      this.showCards.push(false)
      this.showCards[this.activePageNum] = true
    }
  },
  components: {
    "card-header": CreateByStepCardHeader,
    "next-button": NextButton,
  },
  watch: {
    activePageNum(pageNumber) {
      //履歴のカードが表示されたら履歴を取得する。
      if(pageNumber === 1){
        this.getTemplates()
      }
    },
  },
  data() {
    return {
      activePageNum: 0,
      numOfCards: 11,
      startDateOptions: [
        {label: '今すぐ', active: false},
        {label: '明日', active: false},
      ],
      endDateOptions: [
        {label: '今すぐ', active: false},
        {label: '明日', active: false},
        {label: '次の土曜日', active: false},
        {label: '次の日曜日', active: false},
      ],
      finishDateOptions: [
        {label: '始めた日', active: false},
        {label: '開始後３日', active: false},
        {label: '開始後７日', active: false},
        {label: '今月末', active: false},
      ],
      showCards: [],
      templates: [],
      item: {
        title: '',
        start_date: this.getShortDate(null, 'JST', 8),
        end_date: this.getShortDate(null, 'JST', 8),
        finish_date: this.getShortDate(null, 'JST', 8),
        client_id: this.$store.state.user_id,
        family_id: this.$store.state.family_id,
        price: 1500,
        status: undefined,  //APIに送信する前に管理者は1でそれ以外は提案(=2)として登録します。
        job_detail: '',
        worker_id: 0,
        category_id: 1,
        links: [{title: "参考ページ", href: ""}]
      },
      //最後の確認画面で編集するかどうかのアイコンをクリックしたときに参照するスイッチフラグ
      item_disabled: {
        title: true,
        start_date: true,
        end_date: true,
        finish_date: true,
        price: true,
        job_detail: true,
        category_id: true,
      },
      //バリデーションチェックのフラグ
      item_validation: {
        title: undefined,
        start_date: undefined,
        end_date: undefined,
        finish_date: undefined,
        price: undefined,
        job_detail: undefined,
      },
      //「作成する」ボタンの二度押し防止用の
      buttonCreateJob: false
    }
  },
  computed: {
    isBlank: function(){
      return function(value){
        if(value === ''){
          return false
        }
        return true
      }
    },
    isNumber: function(){
      return function(value){
        if(value === ''){
          return false
        }
        return true
      }
    }
  },
  methods: {
    initData(){
      this.showCards = []
      for(let i=0;i < this.numOfCards;i++){
        this.showCards.push(false)
        this.showCards[this.activePageNum] = true
      }
      this.templates = []
      // this.buttonCreateJob = false
      this.item = {
        title: '',
        start_date: this.getShortDate(null, 'JST', 8),
        end_date: this.getShortDate(null, 'JST', 8),
        finish_date: this.getShortDate(null, 'JST', 8),
        client_id: this.$store.state.user_id,
        family_id: this.$store.state.family_id,
        price: 1500,
        status: undefined,
        job_detail: '',
        worker_id: 0,
        category_id: 1,
        links: [{title: "参考ページ", href: ""}]
      }
      this.item_disabled = {
        title: true,
        start_date: true,
        end_date: true,
        finish_date: true,
        price: true,
        job_detail: true,
        category_id: true,
      }
      //バリデーションチェックのフラグ
      this.item_validation = {
        title: undefined,
        start_date: undefined,
        end_date: undefined,
        finish_date: undefined,
        price: undefined,
        job_detail: undefined,
      }
    },
    clickDate(index, type){
      if(type === "startDate"){
        this._start_date(index)
      }else if(type === "endDate"){
        this._end_date(index)
      }else if(type === "finishDate"){
        this._finish_date(index)
      }
    },
    _finish_date(index){
      if(index === 0){
        this.item.finish_date = this.item.end_date
      }else if(index === 1){
        this.item.finish_date = this.subDate(this.item.end_date, -3)
      }else if(index === 2){
        this.item.finish_date = this.subDate(this.item.end_date, -7)
      }else if(index === 3){
        this.item.finish_date = this.getDate(this.getLastDateOfThisMonth())
      }
      this.finishDateOptions.map(item => item['active'] = false)
      this.finishDateOptions[index]['active'] = true
    },
    _end_date(index){
      if(index === 0){
        this.item.end_date = this.getDate()
      }else if(index === 1){
        this.item.end_date = this.subDate(this.getDate(), -1)
      }else if(index === 2){
        this.item.end_date = this.getDate(this.getNextSaturday())
      }else if(index === 3){
        this.item.end_date = this.getDate(this.getNextSunday())
      }
      this.endDateOptions.map(item => item['active'] = false)
      this.endDateOptions[index]['active'] = true
    },
    _start_date(index){
      if(index === 0){
        this.item.start_date = this.getDate()
      }else if(index === 1){
        this.item.start_date = this.subDate(this.getDate(), -1)
      }
      this.startDateOptions.map(item => item['active'] = false)
      this.startDateOptions[index]['active'] = true

    },
    clickTemplateItem(item){
      this.templates.map(template => {template['active'] = false})
      item['active'] = true
      this.item.title = item.title
      this.item.job_detail = item.job_detail
      this.item.price = item.price
      this.item.category_id = item.category_id
      this.item.links = item.links
    },
    async getTemplates(){
      this.templates = await this._getTemplates(15, 0)
    },
    progressValue(){
      return (this.activePageNum / (this.showCards.length - 1)) * 100
    },
    moveToZero(){
      //バリデーションを初期化
      Object.keys(this.item_validation).map(item => {this.item_validation[item] = undefined})
      this.activePageNum = 0
      this.showCards.splice(this.showCards.indexOf(true), 1, false)
      this.showCards.splice(0, 1, true)

    },
    moveBackCard(){
      //バリデーションを初期化
      Object.keys(this.item_validation).map(item => {this.item_validation[item] = undefined})
      this.activePageNum = this.showCards.indexOf(true) - 1
      this.showCards.splice(this.showCards.indexOf(true), 1, false)
      this.showCards.splice(this.activePageNum, 1, true)
    },
    moveNextCard(fieldValue){
      if(fieldValue === "price"){
        if(!this.isNumber(this.item[fieldValue])){
          this.item_validation[fieldValue] = false
          return
        }
      }else{
        if(!this.isBlank(this.item[fieldValue])){
          this.item_validation[fieldValue] = false
          return
        }
      }
      this.activePageNum = this.showCards.indexOf(true) + 1
      this.showCards.splice(this.showCards.indexOf(true), 1, false)
      this.showCards.splice(this.activePageNum, 1, true)
    },
    moveNextNextCard(){
      this.activePageNum = this.showCards.indexOf(true) + 2
      this.showCards.splice(this.showCards.indexOf(true), 1, false)
      this.showCards.splice(this.activePageNum, 1, true)
    },
    async createJob() {
      this.buttonCreateJob = true
      this.item.links.map((link, index) => {
        if (link['href'] === "" || link['title'] === "") {
          this.item.links.splice(index, 1)
        }
      })
      if(this.$store.state.is_admin){
        this.item.status = 1
      }else{
        this.item.status = 2
      }
      const response = await this._createItem(this.item)
      this.$emit('update:show', 'sent')
      this.initData()
    }

  }
}
</script>

<style scoped>
.anim-box.zoomin.is-animated {
  animation: zoomIn 0.8s cubic-bezier(0.25, 1, 0.5, 1) 1 forwards;
}

@keyframes zoomIn {
  0% {
    transform: scale(0.8);
    opacity: 0;
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
</style>
