<template>
  <div style="width: 100%; padding: 0 0 15px 0">
    <CButton
      v-if="false"
      color="dark"
      @click="getItems"
    >GET
    </CButton>

    <div v-if="_items.length">
      <div v-for="(item, index) in _items(itemFilter)" :key="index">
        <CRow class="item-row">
          <CCol :lg="itemColLg(item)">
            <CRow>
              <!-- お仕事の内容が記載されてるメインカラム -->
              <CCol lg="11" col="9" style="margin-top: 10px">
                <CBadge class="item-badge" :color="statusColor(item['status'])">
                  {{ statusText(item['status']) }}
                </CBadge>
                <!-- いいねボタン -->
                <span style="margin-left:0.5rem;margin-right:0.2rem;">
                  <span @click="createFavorite(item, index)">
                    <CIcon name="cil-thumb-up" size="lg" style="color:grey;"/>
                  </span>
                  <!-- いいねがある場合のいいね数 -->
                  <sup v-if="item['favorites'].length > 0" style="top: -0.9em;">
                    <CBadge
                      @mouseenter="item.show_favorites_sw = true"
                      @click="item.show_favorites_sw = false"
                      style="margin-left:-0.5em;" shape="pill" color="success">
                      {{ item['favorites'].length }}
                    </CBadge>
                    <!-- いいね！履歴のドロップダウン -->
                    <div v-show="item.show_favorites_sw" class="dropdown-menu show">
                      <div
                        @click="item.show_favorites_sw = false"
                        class="text-right"
                        style="cursor:pointer; padding:5px 10px 5px 5px"
                      >
                        <CIcon name="cil-x" size="sm"/>
                      </div>
                      <CDropdownItem v-for="favorite in item['favorites']">
                        <span>
                          {{ getShortDate(str_date = favorite['created'], tz = 'JST', date_format = 7) }}
                        </span>
                        <CImg
                          style="width:auto;height:20px;margin:0 10px 0 10px"
                          shape="rounded-circle"
                          :src="imagePath(favorite['user_id'])"
                        />
                        <span
                          v-if="favorite['user_id'] === $store.state.user_id"
                          @click="updateFavorite(favorite, index)"
                          style="margin-right: 5px">
                          <CIcon :content="$options.x_circle"/>
                        </span>
                      </CDropdownItem>
                    </div>
                  </sup>
                </span>
                <!-- コメントボタン -->
                <span style="margin-left:0.2rem;margin-right:0.5rem;">
                  <span @click="showModalComment(item, index)" style="cursor: pointer">
                    <CIcon name="cil-comment-bubble" size="lg" style="color:grey"/>
                  </span>
                  <sup
                    v-if="item['offers'].length > 0" style="top: -0.9em;cursor: pointer;">
                    <CBadge
                      @click="item.offers_sw = !item.offers_sw"
                      style="margin-left:-0.9em;" shape="pill" color="warning">
                      {{ item['offers'].length }}
                    </CBadge>
                  </sup>
                </span>
                <!-- 写真アップボタン -->
                <span style="margin-left:0.2rem;margin-right:0.5rem;cursor:pointer">
                  <span @click="uploadPicture(item, index)">
                    <CIcon name="cil-camera" size="lg" style="color:grey;"/>
                  </span>
                  <sup
                    @click="showImages(item, index)"
                    v-if="item['images'].length > 0" style="top: -0.9em;">
                    <CBadge style="margin-left:-0.9em;" shape="pill" color="info">
                      {{ item['images'].length }}
                    </CBadge>
                  </sup>
                </span>
                <!-- 編集ボタン -->
                <span v-if="user_id === item['client_id']"
                  @click="moveToEditJob(item)" style="margin-left:0.2rem;margin-right:0.5rem;cursor:pointer">
                  <CIcon name="cil-pen" size="lg" style="color:grey;"/>
                </span>

                <!-- お仕事タイトル -->
                <h5 style="margin-top: 0.5rem">
                  <CLink @click="moveToViewJob(item)">{{ item['title'] }}</CLink>
                </h5>
              </CCol>
              <!-- 依頼者のアイコンと仕事する人が決まったらその人のアイコン -->
              <CCol lg="1" col="3" class="text-right">
                <CRow>
                  <!-- 依頼者のアイコン -->
                  <CCol lg="3" col="7" style="padding:0" class="text-right">
                    <CImg
                      style="width:auto;height:40px;margin-top: 10px"
                      shape="rounded-circle"
                      :src="imagePath(item['client_id'])"
                    />
                  </CCol>
                  <CCol v-if="assignStatus(item['worker_id'])" lg="9" col="5" style="padding:0" class="text-right">
                    <CImg
                      style="width:auto;height:40px;margin-top: 10px"
                      shape="rounded-circle"
                      :src="imagePath(item['worker_id'])"
                    />
                  </CCol>
                </CRow>
              </CCol>
              <!-- 日付とお仕事の詳細説明の行 -->
              <CCol col="12" style="margin-bottom: 10px">
                <dl class="row" style="margin-bottom: 0">
                  <CCol col="12">
                    <dl class="row">
                      <!-- 完了表示の場合の「１２月１９日に完了しました。」の表示 -->
                      <dt v-if="itemFilter === 9" class="col-sm-12">
                        <div><span class="emphasize-text">{{getShortDate(item['modified'], 'JST', 9)}}</span>に終了しました。</div>
                      </dt>
                      <!-- 募集中表示の場合の「残り５日」の表示 -->
                      <dt v-else-if="itemFilter === 1" class="col-sm-12">
                        <div>あと<span class="emphasize-text">{{dateDiff(item['end_date'])}}日</span>です！</div>
                      </dt>
                      <!-- 全部表示の場合の募集期間やお仕事日程の表示 -->
                      <dt v-else class="col-sm-12">
                        <div>
                          募集期間: {{ getShortDate(str_date = item['end_date'], tz = 'JST', date_format = 4) }} まで
                        </div>
                        <div>
                          お仕事日程: {{ getShortDate(str_date = item['end_date'], tz = 'JST', date_format = 4) }}
                          〜 {{ getShortDate(str_date = item['finish_date'], tz = 'JST', date_format = 9) }}
                        </div>
                      </dt>

                      <dd class="col-sm-12" style="margin-bottom: 0">
                        <p style="margin-bottom: 0">
                          {{ item['job_detail'] }}
                        </p>
                        <!-- リンク -->
                        <div v-if="item.links.length > 0">
                          <span v-for="link in item.links" style="padding-right: 10px">
                            <CLink
                              target="_blank"
                              :href="link.href">
                              <CIcon name="cil-external-link"/>
                              {{ link.title }}
                            </CLink>
                          </span>
                        </div>
                      </dd>
                    </dl>
                  </CCol>
                </dl>
              </CCol>
              <!-- オファーを表示するリスト -->
              <CCol v-if="item['offers'].length > 0 && item['offers_sw'] === true"
                    style="margin-bottom: 10px;width:100%">
                <CListGroup style="width:100%">
                  <CListGroupItem v-for="offer in item['offers']" :key="offer['id']">
                    <CRow>
                      <CCol col="2" lg="1">
                        <CImg
                          style="width:auto;height:40px"
                          shape="rounded-circle"
                          :src="imagePath(offer['worker_id'])"
                        />
                      </CCol>
                      <CCol col="10" lg="9">
                        <div style="font-weight: bolder">
                          <span>
                            <CBadge
                              :color="offerColor(offer['offer_type'])"
                            >{{ offerText(offer['offer_type']) }}
                            </CBadge>
                          </span>
                          <!-- オファーのいいねボタン -->
                          <span style="margin-left:0.5rem;margin-right:0.2rem;">
                            <span @click="createOfferFavorite(item, index, offer)">
                              <CIcon name="cil-thumb-up" size="lg" style="color:grey;cursor:pointer"/>
                            </span>
                            <sup v-if="offer['favorites'].length > 0" style="top: -0.9em;">
                              <CBadge
                                @mouseenter="offer.show_favorites_sw = true"
                                @click="offer.show_favorites_sw = false"
                                style="margin-left:-0.5em;" shape="pill" color="success">
                                {{ offer['favorites'].length }}
                              </CBadge>
                              <!-- オファーのいいね！履歴のドロップダウン -->
                              <div v-show="offer.show_favorites_sw" class="dropdown-menu show">
                                <div
                                  @click="offer.show_favorites_sw = false"
                                  class="text-right"
                                  style="cursor:pointer; padding:5px 10px 5px 5px"
                                >
                                  <CIcon name="cil-x" size="sm"/>
                                </div>
                                <CDropdownItem v-for="favorite in offer['favorites']">
                                  <span>
                                    {{ getShortDate(str_date = favorite['created'], tz = 'JST', date_format = 7) }}
                                  </span>
                                  <CImg
                                    style="width:auto;height:20px;margin:0 10px 0 10px"
                                    shape="rounded-circle"
                                    :src="imagePath(favorite['user_id'])"
                                  />
                                  <span
                                    v-if="favorite['user_id'] === $store.state.user_id"
                                    @click="updateFavorite(favorite, index)"
                                    style="margin-right: 5px">
                                    <CIcon :content="$options.x_circle"/>
                                  </span>
                                </CDropdownItem>
                              </div>
                            </sup>
                          </span>
                          <wbr>
                          <span style="display:inline-block">
                            {{ getShortDate(str_date = offer['created'], tz = 'JST', date_format = 6) }}
                          </span>
                        </div>
                        <div>
                          {{ offer['offer_text'] }}
                        </div>
                      </CCol>
                      <CCol v-if="offer['worker_id'] === $store.state.user_id" class="text-right">
                        <CButton
                          size="sm"
                          variant="outline"
                          shape="pill"
                          color="secondary"
                          @click="deleteOffer(offer, index)"
                        >削除
                        </CButton>
                      </CCol>
                      <CCol v-if="showAcceptOfferButton(item, offer)" class="text-right">
                        <CButton
                          size="sm"
                          variant="outline"
                          shape="pill"
                          color="warning"
                          @click="assignJob(offer)"
                        >お願いする！
                        </CButton>
                      </CCol>
                      <CCol v-if="showFinishJobButton(item, offer)" class="text-right">
                        <CButton
                          size="sm"
                          variant="outline"
                          shape="pill"
                          color="warning"
                          @click="finishJob(offer)"
                        >完了しました！
                        </CButton>
                      </CCol>
                    </CRow>
                  </CListGroupItem>
                </CListGroup>
              </CCol>
            </CRow>
          </CCol>
          <!-- 画像のスライダー -->
          <CCol v-if="item['images'].length > 0" lg="3">
            <CCarousel
              arrows
              indicators
              animate>
              <CCarouselItem
                v-for="image in item.images"
                :image="mainPicture(image)"
              />
            </CCarousel>
          </CCol>
          <CCol v-else-if="ogpImages(item.links)" lg="3">
            <CCarousel
              arrows
              indicators
              animate>
              <CCarouselItem
                v-for="ogp_image in ogpImages(item.links)"
                :image="ogp_image"
              />
            </CCarousel>
          </CCol>
        </CRow>
      </div>
    </div>
    <!-- コメントのモーダル -->
    <CModal
      title="コメントを残す！"
      size="sm"
      :show.sync="modalShowComment"
      v-on:update:show="modalEvent($event)"
    >
      <template v-slot:default>
        <CRow>
          <CCol>
            <CTextarea
              rows="5"
              v-model="modalMessage"
            ></CTextarea>
          </CCol>
          <CCol lg="12">
            <CInputRadioGroup
              class="col-sm-9"
              :options="[
            {value:'1', label:'応募する'},
            {value:'2', label:'質問する'},
            {value:'3', label:'応援する'},
            ]"
              custom
              inline
              v-bind:checked="modalCheck"
              v-on:update:checked="modalCheck = $event"
            />
          </CCol>
        </CRow>

      </template>
      <template v-slot:footer>
        <CButton
          variant="outline"
          @click="createOffer"
          color="primary"
        >送信
        </CButton>
      </template>
    </CModal>

    <!-- 写真アップロードのモーダル -->
    <CModal
      title="お仕事の写真をアップロード！"
      size="lg"
      :show.sync="modalShowUploadPicture"
      v-on:update:show="modalEventUploadPicture($event)"
    >
      <upload-picture
        :job_id="modalJobId"
        :is_close="modalShowUploadPicture"
        v-on:update:show="modalEventUploadPicture($event)"
      />
      <template v-slot:footer>
        <div></div>
      </template>
    </CModal>

    <!-- アップロードされた写真を見るモーダル -->
    <CModal
      title="お仕事の写真です！！"
      size="xl"
      :show.sync="modalShowImages"
      v-on:update:show="modalEventShowPictures($event)"
    >
      <show-picture
        v-on:update:show="modalEventShowPictures($event)"
        :images="modalImages"/>
      <template v-slot:footer>
        <CCol col="12" lg="2">
            <CButton
              variant="outline"
              style="width:100%"
              size="sm"
              color="primary"
              @click="modalEventShowPictures($event)"
            >
              OK
            </CButton>
        </CCol>
      </template>
    </CModal>
    <!-- Step by stepお仕事作成モーダル -->
    <CModal
      size=""
      :show.sync="modalShowCreateJob"
      v-on:update:show="modalEventCreateJobByStep($event)"
    >
      <create-by-step
        v-on:update:show="modalEventCreateJobByStep($event)"
      />
      <template v-slot:footer>
        <div></div>
      </template>
    </CModal>
  </div>
</template>

<script>
import Comm from '@/views/v1/lib/Comm.js'
import {cilXCircle} from '@coreui/icons'
import UploadPicture from "@/views/v1/components/UploadPicture";
import ShowPicture from "@/views/v1/components/ShowPicture";
import create_step_by_step from "@/views/v1/components/CreateJobByStep";

export default {
  name: "TableJobs",
  mixins: [Comm],/* ここでミックスインを追加 */
  x_circle: cilXCircle,
  components: {
    "upload-picture": UploadPicture,
    "show-picture": ShowPicture,
    "create-by-step": create_step_by_step
  },
  props: {
    addItem: 0,
    itemFilter: 0,
    showCreateJobByStep: false,
    reloadJob: 0,
    searchConditions: undefined
  },
  mounted() {
    // console.log(this.items.length)
    this.getItems()
  },
  data() {
    return {
      //ステップ・バイ・ステップのモーダル
      modalShowCreateJob: false,
      //コメントのモーダル
      modalShowComment: false,
      modalMessage: "",
      modalCheck: "1",
      modalItemId: "",
      modalItemIndex: "",
      //写真アップロードのモーダル
      modalShowUploadPicture: false,
      modalJobId: 0,
      modalJobIndex: 0,
      modalShowImages: false,
      modalImages: [], //モーダルで写真を表示するための変数
      isShow: true,
      // items: [],
      // limit: 7,
      // offset: 0
    }
  },
  watch: {
    reloadJob(){
      this.getItems()
    },
    showCreateJobByStep(value){
      this.modalShowCreateJob = value
    },
    addItem() {
      this.addItems()
    },
    itemFilter(value) {
      // console.log("itemFilter was changed to ", value)
      this.getFilteredItems(String(value))
    },
  },
  computed: {
    _items: function(){
      return function(status){
        //status=0 はすべてのお仕事
        if (status === 0) {
          return this.$store.state.job_items
        } else {
          return this.$store.state.job_items.filter(item => item['status'] === String(status))
        }
      }
    },
    dateDiff: function(){
      return function(date){
        return this._getDateDiffFromNow(date)
      }
    },
    ogpImages: function () {
      return function (links) {
        if (!Array.isArray(links)) return false
        if (links.length === 0) return false
        //OGPが取得できてるリンクの配列を作成
        const _ogp_images = links.map(link => {
          if (Array.isArray(link.ogp)) {
            if (link.ogp.length > 0) return link.ogp.find(ogp_item => ogp_item.key === "image").value
          }
        })
        const ogp_images = _ogp_images.filter(ogp => ogp !== undefined)
        if (ogp_images.length === 0) return false
        return ogp_images
      }
    },
    mainPicture: function () {
      return function (item) {
        return this._get_job_image(item.job_id, item.user_id, item.name)
      }
    },
    imagePath: function () {
      return function (value) {
        return this._get_avatar(value)
      }
    },
    offerColor: function () {
      return function (value) {
        if (value === 1) {
          return 'primary'
        } else if (value === 2) {
          return 'info'
        } else if (value === 3) {
          return 'warning'
        }
      }

    },
    offerText: function () {
      return function (value) {
        if (value === 1) {
          return 'やります！'
        } else if (value === 2) {
          return '質問です！'
        } else if (value === 3) {
          return '応援します！'
        }
      }
    },
    showAcceptOfferButton: function () {
      return function (item, offer) {
        if (item['client_id'] === this.$store.state.user_id &&
          item['client_id'] !== offer['worker_id'] && offer['offer_type'] === 1 &&
          item['worker_id'] === 0) {
          return true
        }
        //提案で来たお仕事
        if (
          item['status'] === "2" &&  // status === 2 は提案
          item['client_id'] !== this.$store.state.user_id &&  // 自分の提案ではないこと
          this.$store.state.is_admin === true &&  // 管理者しか承認できません
          item['worker_id'] === 0){  // まだ誰もアサインされていないこと
          return true
        }
        return false
      }
    },
    showFinishJobButton: function () {
      return function (item, offer) {
        if (item['client_id'] === this.$store.state.user_id &&
          item['client_id'] !== offer['worker_id'] && offer['offer_type'] === 1 &&
          item['status'] === "5") {
          return true
        } else {
          return false
        }
      }
    },
    statusColor: function () {
      return function (value) {
        return this._get_status_color(Number(value))
      }
    },
    statusText: function () {
      return function (value) {
        return this._get_status_text(Number(value))
      }
    },
    assignStatus: function () {
      return function (value) {
        if (this._get_nickname(value) === '不明') {
          return false
        }
        return true
      }
    },
    workerName: function () {
      return function (value) {
        const _name = this._get_nickname(value)
        if (_name === '不明') {
          return '未決定'
        }
        return _name
      }
    },
    workerColor: function () {
      return function (value) {
        const _name = this._get_nickname(value)
        if (_name === '不明') {
          return 'secondary'
        }
        return 'danger'
      }
    },
  },
  methods: {
    itemColLg: function (item) {
      if (item.images.length > 0 || this.ogpImages(item.links)) {
        return 9
      } else {
        return 12
      }
    },
    //登録されているお仕事の写真をモーダルで表示するメソッド
    showImages(item, index) {
      this.modalImages = item['images']
      this.modalJobId = item['id']
      this.modalJobIndex = index
      this.modalShowImages = true
    },
    //Step by Step CreateJob のモーダルが閉じられたときのイベントで呼ばれるメソッド
    async modalEventCreateJobByStep(e){
      if(e === 'sent'){
        await this.getItems()
      }
      // this.modalShowCreateJob = false
      this.$emit("show:create-job-by-step", false)
    },
    //写真のモーダルが閉じられたときのイベントで呼ばれるメソッド
    async modalEventShowPictures(e) {
      this.modalImages = []
      this.modalShowImages = false
      const response = await this._getItem(this.modalJobId)
      //indexはfilterされた配列のindexになっててfilterされてない配列のindexで
      //spliceしないといけないのでfilterされてない配列のindexを_indexとして取得します。
      let _index = undefined
      this.$store.state.job_items.find((job, index) => {
        if(job.id == this.modalJobId) _index = index
      })
      this._spliceItem(_index, response)
      // this.items.splice(this.modalJobIndex, 1, response)
      this.modalJobId = 0
      this.modalJobIndex = 0
    },
    uploadPicture(item, index) {
      console.log('hoge')
      this.modalJobId = item['id']
      this.modalJobIndex = index
      this.modalShowUploadPicture = true
    },
    async deleteOffer(offer, index) {
      // console.log(offer, index)
      if (offer['worker_id'] === this.$store.state.user_id) {
        const item = {
          id: offer['id'],
          offer_type: -1,
          offer_text: offer['offer_text']
        }
        await this._updateOffer(item)
        const response = await this._getItem(offer['job_id'])
        response['offers_sw'] = true
        //indexはfilterされた配列のindexになっててfilterされてない配列のindexで
        //spliceしないといけないのでfilterされてない配列のindexを_indexとして取得します。
        let _index = undefined
        this.$store.state.job_items.find((job, index) => {
          if(job.id == offer['job_id']) _index = index
        })
        this._spliceItem(_index, response)
        // this.items.splice(index, 1, response)
      }
    },
    async updateFavorite(favorite, index) {
      // console.log(favorite)
      if (favorite['user_id'] === this.$store.state.user_id) {
        const item = {
          id: favorite['id'],
          favorite_type: -1
        }
        await this._updateFavorite(item)
        const response = await this._getItem(favorite['job_id'])
        response['show_favorites_sw'] = true
        //indexはfilterされた配列のindexになっててfilterされてない配列のindexで
        //spliceしないといけないのでfilterされてない配列のindexを_indexとして取得します。
        let _index = undefined
        this.$store.state.job_items.find((job, index) => {
          if(job.id == favorite['job_id']) _index = index
        })
        this._spliceItem(_index, response)
        // this.items.splice(index, 1, response)
      }
    },
    // お仕事のステータスを完了にします。
    finishJob(offer) {
      const item = {
        id: offer['job_id'],
        status: 9,
      }
      this._updateItem(item)
    },
    // お仕事のステータスを仕事中にします。
    assignJob(offer) {
      const item = {
        id: offer['job_id'],
        status: 5,
        worker_id: offer['worker_id']
      }
      this._updateItem(item)
    },
    async createOffer() {
      const item = {
        "job_id": this.modalItemId,
        "family_id": this.$store.state.family_id,
        "worker_id": this.$store.state.user_id,
        "offer_type": this.modalCheck,
        "offer_text": this.modalMessage
      }
      await this._createOffer(item)
      const response = await this._getItem(item.job_id)
      response['offers_sw'] = true
      //indexはfilterされた配列のindexになっててfilterされてない配列のindexで
      //spliceしないといけないのでfilterされてない配列のindexを_indexとして取得します。
      let _index = undefined
      this.$store.state.job_items.find((job, index) => {
        if(job.id == item.job_id) _index = index
      })
      this._spliceItem(_index, response)
      // this.items.splice(this.modalItemIndex, 1, response)
      this.modalShowComment = false
      this.modalItemId = ""
      this.modalMessage = ""
      this.modalItemIndex = ""
      this.modalCheck = "1"
    },
    async modalEventUploadPicture(e) {
      this.modalShowUploadPicture = e
      const response = await this._getItem(this.modalJobId)
      //indexはfilterされた配列のindexになっててfilterされてない配列のindexで
      //spliceしないといけないのでfilterされてない配列のindexを_indexとして取得します。
      let _index = undefined
      this.$store.state.job_items.find((job, index) => {
        if(job.id == this.modalJobId) _index = index
      })
      this._spliceItem(_index, response)
      // this.items.splice(this.modalJobIndex, 1, response)
      this.modalJobId = 0
      this.modalJobIndex = 0
    },
    modalEvent(e) {
      if (e === false) {
        this.modalItemId = ""
        this.modalMessage = ""
        this.modalItemIndex = ""
        this.modalCheck = "1"
      }
    },
    showModalComment(item, index) {
      this.modalShowComment = true
      this.modalItemId = item.id
      this.modalItemIndex = index
    },
    moveToViewJob(item) {
      this.$router.push(
        {
          name: 'ViewJob',
          params: item
        }
      )
    },
    //オファーにいいねをつけます。
    async createOfferFavorite(item, index, offer) {
      const _item = {
        "job_id": item.id,
        "offer_id": offer.id,
        "family_id": this.$store.state.family_id,
        "user_id": this.$store.state.user_id
      }
      // console.log(_item)
      // return
      await this._createFavorite(_item)
      const response = await this._getItem(item.id)
      response['offers_sw'] = true
      //indexはfilterされた配列のindexになっててfilterされてない配列のindexで
      //spliceしないといけないのでfilterされてない配列のindexを_indexとして取得します。
      let _index = undefined
      this.$store.state.job_items.find((job, index) => {
        if(job.id == item.id) _index = index
      })
      this._spliceItem(_index, response)
      // this.items.splice(index, 1, response)
    },
    //ジョブにいいねをつけます。
    async createFavorite(item, index) {
      const _item = {
        "job_id": item.id,
        "family_id": this.$store.state.family_id,
        "user_id": this.$store.state.user_id
      }
      //indexはfilterされた配列のindexになっててfilterされてない配列のindexで
      //spliceしないといけないのでfilterされてない配列のindexを_indexとして取得します。
      let _index = undefined
      this.$store.state.job_items.find((job, index) => {
        if(job.id == item.id) _index = index
      })
      await this._createFavorite(_item)
      const response = await this._getItem(item.id)
      this._spliceItem(_index, response)
      // this.items.splice(index, 1, response)
    },
    moveToEditJob(item) {
      // console.log(item)
      this.$router.push(
        {
          name: 'EditJob',
          params: {"_item": item}
        }
      )
    },
    //追加でお仕事を読み込む下の矢印のボタンを押されたときに呼ばれるメソッド
    async addItems() {
      await this._addItems(this.searchConditions)
      // this.items = this.$store.state.job_items
      // this.getFilteredItems(String(this.itemFilter))
    },
    async getItems() {
      await this._getItems(this.searchConditions)
      // console.log(this.searchConditions)
      // this.items = this.$store.state.job_items
      // this._getOgpFromStoreItems()
      // this.getFilteredItems(String(this.itemFilter))
      this.$emit("update:items", true)
    },
    getFilteredItems(status = "1") {
      //status=0 はすべてのお仕事
      // if (status === "0") {
      //   this.items = this.$store.state.job_items
      // }
      // //status===8は応募か質問がついてるお仕事
      // else if (status === "8") {
      //   this.items = this.$store.state.job_items.filter(item => item['offers'].length > 0)
      // } else {
      //   this.items = this.$store.state.job_items.filter(item => item['status'] === status)
      // }
    },
  }
}
</script>

<style scoped>
.emphasize-text {
  border-bottom: 2px solid #ff894a;
  font-size: 160%;
  color: #ff894a;
  font-weight: bolder;
}

.item-row {
  /*border-top-width: 1px;*/
  /*border-top-color: #414067;*/
  /*border-top-style: solid;*/
  border-bottom-width: 1px;
  border-bottom-color: #414067;
  border-bottom-style: solid;
  padding: 5px 0 5px 0;
}

.item-badge {
  font-size: 100%;
  margin: 0 5px 0 0;
}
</style>
