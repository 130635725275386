<template>
  <div>
    <CCard style="margin-bottom:0">
      <CCardHeader>
        <div class="card-header-actions">
          <CButton
            @click="reloadJob()"
            style="padding:0 10px 0 10px; color:rgba(255, 255, 255, 0.87)"
            variant="ghost"
            v-c-tooltip.mouseenter="{content: 'お仕事一覧を再読み込み'}"
          >
            <CIcon :content="$options.freeSet.cilReload" size="lg"/>
          </CButton>
          <CButton style="padding:0 10px 0 10px; color:rgba(255, 255, 255, 0.87)"
                   v-c-tooltip.mouseenter="{content: 'メンバ一覧表示切替'}"
                   variant="ghost"
                   @click="$store.state.showMemberList = !$store.state.showMemberList">
            <CIcon size="lg" :name="`cil-chevron-${$store.state.showMemberList ? 'bottom' : 'top'}`"/>
          </CButton>
        </div>
      </CCardHeader>
      <CCardBody>
        <CRow>
          <CCol lg="3">
            <my-logo
              v-on:click-create-job="showCreateJobByStep = $event"
            />
          </CCol>
          <CCol lg="9">
            <table-header-jobs
              v-on:header-button="headerEvent($event)"
              v-on:header-select="headerSelectEvent($event)"
            />
            <!-- Nav -->
            <CNav fill variant="tabs" v-if="countLength(0)">
              <CNavItem :active="isActiveNav[0]" v-show="countLength(0)">
                <div @click="itemFilter = 0">
                  全部
                  <CBadge shape="pill" color="light">
                    {{ $store.state.job_items.length }}
                  </CBadge>
                </div>
              </CNavItem>
              <CNavItem :active="isActiveNav[1]" v-show="countLength(1)">
                <div @click="itemFilter = 1">
                  募集中
                  <CBadge shape="pill" color="danger">
                    {{ countLength(1) }}
                  </CBadge>
                </div>
              </CNavItem>
              <CNavItem :active="isActiveNav[2]" v-show="countLength(2)">
                <div @click="itemFilter = 2">
                  提案中
                  <CBadge shape="pill" color="danger">
                    {{ countLength(2) }}
                  </CBadge>
                </div>
              </CNavItem>
              <CNavItem :active="isActiveNav[3]" v-show="countLength(3)">
                <div @click="itemFilter = 3">承認待ち
                  <CBadge shape="pill" color="danger">
                    {{ countLength(3) }}
                  </CBadge>
                </div>
              </CNavItem>
              <CNavItem :active="isActiveNav[4]" v-show="countLength(4)">
                <div @click="itemFilter = 4">
                  交渉中
                  <CBadge shape="pill" color="danger">
                    {{ countLength(4) }}
                  </CBadge>
                </div>
              </CNavItem>
              <CNavItem :active="isActiveNav[5]" v-show="countLength(5)">
                <div @click="itemFilter = 5">
                  仕事中
                  <CBadge shape="pill" color="danger">
                    {{ countLength(5) }}
                  </CBadge>
                </div>
              </CNavItem>
              <CNavItem :active="isActiveNav[9]" v-show="countLength(9)">
                <div @click="itemFilter = 9">
                  完了
                  <CBadge shape="pill" color="danger">
                    {{ countLength(9) }}
                  </CBadge>
                </div>
              </CNavItem>
            </CNav>
            <div v-else>該当するお仕事がありません。</div>
            <!-- お仕事の一覧 -->
            <list-jobs
              :item-filter="itemFilter"
              :reload-job="swReloadJob"
              :search-conditions="searchConditions"
              :show-create-job-by-step="showCreateJobByStep"
              v-on:show:create-job-by-step="showCreateJobByStep = $event"
              v-on:update:items="_getActiveNavNum($event)"
              :add-item="increment"/>
          </CCol>
        </CRow>
      </CCardBody>
    </CCard>
    <!--お仕事の追加読み込みボタン-->
    <CRow>
      <CCol style="width:100%" col="12">
        <CButton
          size="sm"
          variant="ghost"
          color="normal"
          style="width:100%"
          @click="addJobs"
        >
          <CIcon
            style="width:100%;height:2rem;margin-bottom:25px"
            :content="$options.freeSet.cilChevronDoubleDown"/>
        </CButton>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import {freeSet} from '@coreui/icons'
import CTableHeaderJobs from "@/views/v1/components/ListJobsHeader"
import CListJobs from '@/views/v1/components/ListJobs'
import CMyLogo from "@/views/v1/components/MyLogo"
import CreateUser from "@/views/v1/components/CreateUser"

export default {
  name: "ListJobs",
  freeSet,
  props: {},
  mounted() {
    for(let i = 0; i < 10; i++){
      this.isActiveNav.push(false)
    }
  },
  components: {
    "list-jobs": CListJobs,
    "table-header-jobs": CTableHeaderJobs,
    "my-logo": CMyLogo,
    "create-user": CreateUser
  },
  computed: {
    countLength: function () {
      return function (status) {
        if(status === 0){
          return this.$store.state.job_items.length
        }else{
          return this.$store.state.job_items.filter(item => item.status === String(status)).length
        }
      }
    },
    getActiveNavNum: function(){
      //優先度の高いステータスから順番に並んだ配列を作る
      const list_status = [1,2,3,5,9,4,0,-1]
      //ステータス毎にコンテンツ数を取得する
      const active_navs = list_status.map(status => {
        if(this.countLength(status) > 0){
          return status
        }
      }, this)
      //最初にundefined以外が出てきたタブをアクティブにする
      const num = active_navs.find(item => item != undefined)
      //ListJobコンポーネント側でフィルタをかけるために値を更新する
      this.itemFilter = num
      //list.vueのCItemNavのアクティブを更新するために値を更新する
      for(let i = 0; i < 10; i++){
        this.isActiveNav.splice(i, 1, false)
      }
      this.isActiveNav.splice(Number(num), 1, true)
      return num
    },
  },
  data() {
    return {
      isActiveNav: [],
      increment: 1,//追加で情報を読み込むためのフラグ,
      itemFilter: this.getActiveNavNum,//ListJobsコンポーネントで表示するitemのフィルター条件
      showCreateJobByStep: false,//Step by Step でお仕事を作るモーダルを表示するフラグ
      swReloadJob: undefined,//ジョブのリロードボタンが押されたら値が更新されます
      searchConditions: {
        status: undefined,
        client_id: undefined,
        worker_id: undefined,
        order: undefined,
        limit: 7,
        offset: 0},//ヘッダの検索条件を指定された場合に値が入ってきます。
    }
  },
  methods: {
    _getActiveNavNum(){
      const hoge = this.getActiveNavNum
    },
    addJobs() {
      this.searchConditions.offset = this.searchConditions.offset + this.searchConditions.limit
      this.increment += 1
    },
    reloadJob() {
      this.searchConditions.offset = 0
      const c = "abcdefghijklmnopqrstuvwxyz0123456789"; // 生成する文字列に含める文字セット
      const cl = c.length
      let r = ""
      for (let i = 0; i < 10; i++) {
        r += c[Math.floor(Math.random() * cl)]
      }
      this.swReloadJob = r
    },
    headerSelectEvent(e) {
      Object.keys(e).map(item => {
        this.searchConditions[item] = e[item]
      }, this)
      this.reloadJob()
    },
    headerEvent(e) {
      if (e === 'create') {
        this.showCreateJobByStep = true
      }
    },
  }
}
</script>

<style scoped>
</style>
