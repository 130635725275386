<template>
  <div v-show="!$store.state.showMemberList" style="width: 100%; padding: 25px 0 10px 0">
    <CButton v-if="false" @click="clientOptions">ボタン</CButton>
    <CRow>
      <CCol lg="4">
        <CInput disabled placeholder="お仕事検索">
          <template v-slot:append>
            <CButton disabled color="secondary" style="padding:0 12px 0 12px">
              <CIcon size="sm" name="cil-search"/>
            </CButton>
          </template>
        </CInput>
      </CCol>
      <CCol lg="8">
        <CRow>
          <CCol lg="3" xs="6">
            <CSelect
              placeholder="募集状況"
              :value.sync="statusOption"
              :options="statusOptions"
            />
          </CCol>
          <CCol lg="3" xs="6">
            <CSelect
              placeholder="依頼者"
              :value.sync="clientOption"
              :options="clientOptions()"
            />
          </CCol>
          <CCol lg="3" xs="6">
            <CSelect
              placeholder="並び替え"
              :value.sync="orderOption"
              :options="orderOptions"
            />
          </CCol>
          <CCol lg="3">
            <CButton
              style="width:100%"
              @click="headerButton('create')"
              variant=""
              color="primary"
              class="edit-button"
              type="submit">お仕事の追加
            </CButton>
          </CCol>
        </CRow>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import {freeSet} from '@coreui/icons'
import Comm from '@/views/v1/lib/Comm.js'

export default {
  name: "TableHeaderJobs",
  mixins: [Comm],/* ここでミックスインを追加 */
  freeSet,
  props: {},
  computed: {},
  watch: {
    statusOption(){this.headerSelect()},
    clientOption(){this.headerSelect()},
    orderOption(){this.headerSelect()},
  },
  data() {
    return {
      statusOption: undefined,
      clientOption: undefined,
      orderOption: undefined,
    }
  },
  methods: {
    clientOptions(){
      const options = this.$store.state.your_family.map(member => {
        return {
          value: member.id,
          label: member.nickname
        }
      })
      options.unshift({value:0, label:"全員"})
      return options
    },
    headerButton(button_type) {
      this.$emit('header-button', button_type)
    },
    headerSelect(){
      const item = {
        status: this.statusOption,
        client_id: this.clientOption,
        worker_id: 0,
        order: this.orderOption
      }
      this.$emit('header-select', item)
    }
  }
}
</script>

<style scoped>

</style>
